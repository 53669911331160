
<!--异常提报明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">异常提报</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">返回</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="infoForm"
            :model="info"
            label-width="120px"
            label-position="left"
        >
          <div class="title">
            <div>基本信息</div>
            <div :style="{color:info.state === 0 ? '#EA000E' : info.state === 1 ? '#F7B515' : info.state === 2 ? '#37C239' :'#777474',fontWeight:'normal' }">{{info.state === 0 ? '未提报' : info.state === 1 ? '未审核' : info.state === 2 ? '已审核' :'超时取消'}}</div>
          </div>
          <el-form-item label="设备名称" prop="name">
            <div>{{info.name}}</div>
          </el-form-item>
          <el-form-item label="额定待机时长" prop="" style="max-width: 1000px">
            <div>{{info.ratedTime}}</div>
          </el-form-item>
          <el-form-item label="开机时长" prop="code">
            <div>{{info.startTime}}</div>
          </el-form-item>
          <el-form-item label="结束时长" prop="name">
            <div>{{info.endTime}}</div>
          </el-form-item>
          <el-form-item label="工作时长" prop="name">
            <div>{{info.workTime}}</div>
          </el-form-item>
          <el-form-item label="异常待机时长" prop="name">
            <div>{{info.actualTime}}</div>
          </el-form-item>
          <el-form-item label="提报单号" prop="" style="max-width: 1000px">
            <div>{{info.code}}</div>
          </el-form-item>
          <el-form-item label="提报时间" prop="code">
            <div>{{info.date}}</div>
          </el-form-item>
          <el-form-item label="提报原因" prop="name">
            <div>{{info.reason}}</div>
          </el-form-item>
          <el-form-item label="材料原因" v-if="info.materialReason" prop="name">
            <div>{{info.materialReason}}</div>
          </el-form-item>
          <el-form-item label="备注" prop="" style="max-width: 1000px">
            <div>{{info.remark}}</div>
          </el-form-item>
          <el-form-item v-if="!info.examineUser && $store.state.userInfo.roleId === 'e2c38b03-989c-454b-aa65-7035c41ae1fb'" label="审批意见" prop="" style="max-width: 1000px">
            <el-input
                v-model="info.form"
                show-word-limit
                clearable
                type="textarea"
                :rows="4"
                placeholder="请输入审批意见"
                maxlength="100"
            />
          </el-form-item>
          <div v-if="info.examineUser">
            <div class="title">审批信息</div>
            <el-form-item label="审批意见" prop="code">
              <div>{{info.result}}</div>
            </el-form-item>
            <el-form-item label="审批人" prop="" style="max-width: 1000px">
              <div>{{info.examineUser}}</div>
            </el-form-item>
            <el-form-item label="审批时间" prop="code">
              <div>{{info.examineDate}}</div>
            </el-form-item>
          </div>
          <el-form-item v-if="!info.examineUser">
            <el-button type="primary" @click="submit" class="submit-btn" v-if="!info.examineUser && $store.state.userInfo.roleId === 'e2c38b03-989c-454b-aa65-7035c41ae1fb'">
              提交
            </el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'

export default {
  name: "abnormalDetail",
  data() {
    return {
      hide: false,
      isUpdate: true,
      info: {
        form: ''
      },
      form: {
        name: '',
        page: 1,
        count: 100000000,
        teamId: ''
      },
    }
  },
  created() {
    console.log(this.$store.state.userInfo.roleId)
    this.updateDevice()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    updateDevice() {
      API.getExcetionDetail(this.$route.query.id).then(res => {
        console.log(res)
        if (res.message.success) {
          this.info = res.message.data;
        } else {
          message('error',res.message.message)
        }
      })
    },
    submit() {
      if (!this.info.form) {
        this.$message({
          message: '请输入审批意见',
          type: 'warning',
        })
        return
      }
      const obj = {
        id: this.$route.query.id,
        result: this.info.form,
      }
      API.abnormalApprove(obj).then(res => {
        if (res.message.success) {
          this.$message({
            message: '提交成功',
            type: 'success',
          })
          this.$router.back()
        } else {
          this.$message({
            message: response.message.message,
            type: response.message.success ? 'success' : 'error',
          })
        }
      })
    }
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

}
.title div:last-child{
  font-size: 16px;
  font-weight: 400;
}
.el-table .hidden-row {
  display: none;
}
</style>
